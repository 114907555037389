//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      input: {},
      constant,
    };
  },

  computed: {
    ...mapState('merchantsFees', ['currencies', 'providers']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        deposit: true,
        currency: '',
        method: '',
        provider: '',
        feeAbs: 0,
        feeRel: 0,
        feeMin: 0,
        feeMax: 0,
        fixedFee: undefined,
      };
    },
    isEditingDisabled() {
      return this.input.feeAbs === ''
        || this.input.feeRel === ''
        || this.input.feeMin === ''
        || this.input.feeMax === '';
    },
    isCreatingDisabled() {
      return this.input.deposit === undefined
        || !this.input.currency
        || !this.input.method
        || !this.input.provider
        || this.input.feeAbs === ''
        || this.input.feeRel === ''
        || this.input.feeMin === ''
        || this.input.feeMax === '';
    },
    depositList() {
      return [
        { caption: 'Deposit', id: true },
        { caption: 'Withdrawal', id: false },
      ];
    },
    providerList() {
      return this.providers
        .filter((e) => e.enabled)
        .map((e) => e.name);
    },
    providerCurrencyList() {
      const provider = this.providers.find((e) => e.name === this.input.provider);
      if (!provider) return [];
      return provider.currencies
        .filter((e) => e.enabled)
        .map((e) => e.name);
    },
    providerMethodList() {
      const provider = this.providers.find((e) => e.name === this.input.provider);
      if (!provider) return [];
      const currency = provider.currencies.find((e) => e.name === this.input.currency);
      if (!currency) return [];
      if (this.input.deposit === undefined) return [];
      return currency[this.input.deposit ? 'deposits' : 'withdrawals']?.map((e) => e.method);
    },
  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
        } else {
          const model = {};
          Object.keys(defaultInput).forEach((key) => {
            model[key] = data[key];
          });
          this.$set(this, 'input', model);
        }
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
    deleteFee() {
      const { input } = this;
      this.$emit('delete', { input });
    },
  },
};
