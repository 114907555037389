//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        currency: [],
        provider: [],
        method: [],
      },
    };
  },

  computed: {
    ...mapState('merchantsFees', [
      'filterLists',
      'providers',
      'currencies',
    ]),
    ...mapGetters('merchantsFees', [
      'currencyList',
      'providerList',
      'methodList',
    ]),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('merchantsFees', ['setFilters']),

    toObject(arr) {
      return arr.map((caption, id) => ({ id, caption }));
    },
  },
};
